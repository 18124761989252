import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import configs from "../../site-config"
import "../css/site.css"
import Header from "../components/header"

const Templates = ({ data }) => (
  <Layout>
    <SEO title="5/3/1 Templates" />
    <Header />
    <div className="m-6">
      <h1 className="text-5xl">All 5/3/1 Templates</h1>
      {data.allTemplates.edges
        .sort((a, b) => (a.node.name > b.node.name ? 1 : -1))
        .map(edge => {
          const template = edge.node
          return (
            <Link
              key={template.name}
              to={`/template/${encodeURI(
                template.name
                  .replace(/\s/g, "")
                  .replace(/\//g, "-")
                  .replace(/%/g, "")
                  .replace(/’/g, "")
                  .replace(/'/g, "")
                  .replace(/,/g, "")
                  .replace(/’/g, "")
              )}`}
            >
              <p>{template.name}</p>
            </Link>
          )
        })}
    </div>
  </Layout>
)

export default Templates

export const query = graphql`
  query {
    allTemplates {
      edges {
        node {
          name
        }
      }
    }
  }
`
